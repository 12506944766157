export const CUSTOMER_STATUSES = [
	{ title: 'Pending', class: 'orange' },
	{ title: 'Inactive', class: 'inactive' },
	{ title: 'Active', class: 'success' },
];

export const CUSTOMER_STATUS_OPTIONS = [
	{ name: 'All status', value: null },
	{ name: 'Pending', value: 'pending' },
	{ name: 'Active', value: 'active' },
	{ name: 'Inactive', value: 'inactive' },
];

export const CUSTOMER_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	PENDING: 'pending',
};
export const CUSTOMER_STATUS_COLOR = {
	[CUSTOMER_STATUS.ACTIVE]: 'success',
	[CUSTOMER_STATUS.INACTIVE]: 'info',
	[CUSTOMER_STATUS.PENDING]: 'orange',
};

export const CUSTOMER_TYPE_OPTIONS = [
	{ name: 'All types', value: null },
	{ name: 'guest', value: 'guest' },
	{ name: 'member', value: 'member' },
];
